.select2-container--default .select2-selection--single {
    background-color: #f2f2f2;
}

.catItemParent:hover{
    box-shadow: 0px 0px 5px rgba(0,0,0, 0.1);
}

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #00bb00 ;
    box-shadow: 0 1px 0 0 #00bb00 ;
}

.select2-selection__rendered span i{
    position: unset;
    display: inline-flex;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 5px;
}

.parent-category{
    font-size: 16px;
    font-weight: 400;
}

.parent-category i{
    margin-right: 3px;
}

.select2-container--default .select2-selection--single {
    background-color: #ffffff;
}

@media screen and (min-width: 601px) {
    .negotiable-div {
        margin-top: 45px;
    }
}

textarea{
    border: 2px solid #CCCCCC;
    border-radius: 5px;
    height: 100px;
}

.rug .rug-handle:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 4px;
    border: 2px solid #ccc!important;
}

.rug .rug-handle .rug-handle-info .rug-handle-drop-text {
    font-size: 14px!important;
}

.rug .rug-handle svg.rug-handle-icon {
    width: 30px!important;
}

.rug .rug-items.__list {
    padding-top: 5px!important;
    min-height: 20px!important;
}

.rug-list .rug-list-image img {
    width: 100%;
    height: 100%;
    object-fit: cover!important;
}

.rug-list-upload-button{
    display: none!important;
}

.imagePrevItemHolder{
    text-align: center;
}

.imagePrevItemHolder img{
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin: 2px;
}