header .brand-logo img {
    height: 30px;
}

header .brand-logo {
    font-size: 2.1rem;
    line-height: 0;
    position: absolute;
    top: 0;
    display: inline-block;
    margin: 1px 0;
    padding: 17px;
    color: #fff;
}

nav ul a:hover {
    background-color: unset;
}

nav ul a {
    font-size: 1rem;
    display: block;
    padding: 0 15px;
    cursor: pointer;
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
    color: #757575;
}

.navbar .navbar-main {
    padding: 0;
}

#header.fixed{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
}

.sidenav-overlay{
    z-index: 8;
}

.sidenav {
    top: 56px;
    overflow: hidden;
    width: 70%;
    box-shadow: none;
}


.navbar .navbar-main {
    position: relative;
    z-index: 9;
}

@media screen and (max-width: 993px) and (min-width: 601px){
    #header .container{
        padding: 0px;
    }

    header nav.navbar-color ul.left {
        position: relative;
        top: 0px;
        left: 0px;
    }

}

@media screen and (max-width: 600px){
    header nav.navbar-color ul.left {
        position: relative;
        top: 0px;
        left: 0px;
    }

    #header{
        box-shadow: none;
        z-index: 9;
    }

    #header .container{
        padding: 0px;
    }


}