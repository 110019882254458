.ad-list-item img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: #999999;
    border-radius: 5px;

}

.ad-list-item:hover {
    cursor: pointer;
}

.ad-list-item:hover img{
    box-shadow: 0px 2px 10px #999999;
}