.f-cat-item{
    position: relative;
}

.f-cat-item img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.f-cat-details-holder{
    position: absolute;
    width: 100%;
    background-color: rgba(53,53,53,0.64);
    bottom: 0;
    margin-bottom: 6px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 16%, rgba(0,0,0,0) 100%);
    padding: 10px 15px;
}

.f-cat-details-holder:hover{
    border-bottom: 5px solid #00bb00;
    cursor: pointer;
}