.data-tr td{
    font-size: 14px;
    font-weight: 400;
}

tr td{
    padding: 10px 20px
}

tr th{
    padding: 10px 20px
}

.btn-action{
    margin: 5px;
}

.pagination li.active {
    background-color: #00bb00;
}

.pagination li a{
    font-size: 14px;
}

pagination{
    display: inline-block;
}