.sb-category-holder p:hover{
    cursor: pointer;
}

#serachbar{
    max-width: 900px;
    margin: auto;
}

.category-selection-holder{
    min-height: 300px;
    max-width: 450px;
    width: 450px;
    background-color: #ffffff;
    position: absolute;
    z-index: 9;
    margin-left: -1.1%;
    overflow-y: auto;
    max-height: 400px;
}

.category-item{
    padding: 15px;
    color: #757575;
    border-bottom: 1px solid #f2f2f2;

}

.category-item:hover{
    cursor: pointer;
}

.keyword-holder input{
    padding: 16px 0px;
    border: none;
    width: 100%;
}


.location-selection-holder .select2.select2-container {
    margin-top: 5px;
}


.submit-btn-holder button {
    height: 49.3px;
}



.location-selection-holder .select2.select2-container{

}


.keyword-result-holder{
    max-height: 350px;
    background-color: #ffffff;
    position: absolute;
    width: 450px;
    z-index: 9;
    margin-left: -1.1%;
    overflow-y: auto;
}

.keyword-result-itel-list{
    margin: 0px;

}


.keyword-result-itel-list li{
    border-top: 1px solid #f2f2f2;
    padding: 10px 10px;
}

.keyword-result-itel-list li:hover{
    background-color: #fefefe;
    cursor: pointer;
}

#location-search-input{
    padding: 16px 0px;
    border: none;
    width: 100%;
}

.location-selection-holder::before{
    content: '';
    width: 2px;
    height: 30px;
    background-color: #00bb00;
    display: inline-block;
    float: left;
    margin-top: 11px;
    margin-left: -15px;
}


.location-result-holder{
    max-height: 350px;
    background-color: #ffffff;
    position: absolute;
    width: 230px;
    z-index: 9;
    margin-left: -1.1%;
    overflow-y: auto;
}


@media screen and (max-width: 600px){
    .keyword-result-holder{
        max-width: 350px;
        width: 350px;
    }

    .category-selection-holder{
        max-width: 350px;
        width: 350px;
    }

    .location-result-holder{
        max-width: 350px;
        width: 350px;
        margin-top: -1px;
    }

    .location-selection-holder::before{
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px){

}


