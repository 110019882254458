.listItemHeader tr , .listItemBody tr{
    border-bottom: none;
}

.listItemHeader tr td{
    border-bottom: none;
    padding: 0px 15px;
}

.listItemBody tr td{
    border-bottom: none;
    padding: 5px 15px;
}

.listItem.collapsible-body{
    padding: 25px;
}

.listitemIcon{
    font-size: 20px;
}
