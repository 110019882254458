.top-bar-left li{
    display: inline-block;
    margin-right: 20px;

}

.top-bar-left li a{
    color: #ffffff!important;
    font-size: 12px!important;
    font-family: Roboto;
}



.top-bar-right li{
    display: inline-block;
    margin-left: 20px;

}

.top-bar-right li a{
    color: #ffffff!important;
    font-size: 12px!important;
    font-family: Roboto;
}

.material-icons.flex{
    display: inline-flex;
    vertical-align: top;
}