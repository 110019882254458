.related-ad-item img{
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    max-height: 200px;
}

.related-ad-item h6{
    line-height: 25px;
    font-weight: 600;
}

.related-ad-item h6{
    line-height: 25px;
}