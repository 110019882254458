.cover-container{
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 400px;
}

.bg-blur-holder{
    overflow: hidden;
    position: absolute;
    height: 400px;
}

.bg-blur-holder span{
    display: block;
    position: absolute;
    height: 400px;
    background-size: cover;
    filter: blur(30px);
    -webkit-filter: blur(30px);
    z-index: 1;
    box-shadow: none;
    overflow: hidden;
}

.feature-image-holder{
    height: 400px;
    background-color: rgba(0,0,0,0.3);
    position: relative;
    z-index: 2;
    text-align: center;
}



.feature-image{
    height: 400px;
    object-fit: contain;
    max-width: 760px;
}

#views-count{
    display: inline-block;
    margin-top: 7px;
    color: #757575;
    font-weight: 300;
}


.media-actions-container{
    position: absolute;
    z-index: 3;
    height: 400px;
}

.social-share-btns{
    position: relative;
    top: 100px;
    left: 30px;
    width: 70px;
}

.social-share-btns a{
    display: inline-block;
    margin: 5px;
}

.gallery-btn-link{
    position: absolute;
    right: 30px;
    bottom: 30px;
}

.main-action-link-holder button{
    color: #757575;
}

#title{
    font-size: 18px;
    font-weight: 600;
}

#price_amount{
    font-size: 30px;
}

.price-container{
    max-width: 150px;
}

.price-container small {
    line-height: 30px;
    display: inline-block;
    position: absolute;
    margin-top: 8px;
    margin-left: 20px;
}

.location-holder{
    font-size: 14px;
}


.owner-info-holder{
    padding: 10px;
}

.owner-info-holder img{
    width: 70px;
    height: 70px;
    border-radius: 100px;
}

.owner-info-holder .details{
    display: inline-block;
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
}

.owner-info-holder .details p{
    color: #757575;
    font-size: 16px;
}

.owner-info-holder .details p.name{
    color: #000000;
    font-weight: bold;

}

.owner-info-holder .details p.date{
    font-size: 14px;
}

.owner-info-holder .details p.date i{
    font-size: 20px;
}

#message{
    padding: 10px;
}

.product-tab li.indicator{
    display: none;
}

.product0tab-content-holder{
    background-color: #f8f8f8;
}

.product-tab li.tab{
    background-color: #ffffff;
}

.product-tab li.tab a.active{
    background-color: #f8f8f8;
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
    outline: none;
    background-color: rgb(0 187 0 / 19%);
}

.product-tab li.tab a{
    color: #757575;
}

.product-tab li.tab a.active{
    color: #000000;
}

.image-holder{
}

.itm-image{
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 3px;
    margin: 10px 10px 0px 0px;
    border: 1px solid #ccc;
    background-color: #ffffff;
}

@media screen and (max-width: 600px){
    .main-action-link-holder button {
        padding-right: 10px;
        padding-left: 10px;
    }

    .itm-image{
        min-width: 50px;
        max-width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 3px;
        margin-top: 0px;
        border: 1px solid #ccc;
        background-color: #ffffff;
    }

}

